<script>
  import { PLAN_PRICE } from '@/helpers/constants'
  /**
   * Based off `HomeWidgetContent` modal template to keep consistency
   */
  export default {
    props: {
      /** Whether the modal is currently open (visible). */
      open: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        PLAN_PRICE,
      }
    },
  }
</script>

<template>
  <Portal to="modal">
    <BaseModal
      size="sm-md"
      height="md"
      :open="open"
      bg-color="dawn"
      hide-nav-on-mobile
      dismissible
      sticky-icon
      mobile-full-screen
      @dismiss="$emit('dismiss')"
    >
      <div>
        <div class="text-2xs mb-5 uppercase">Membership Details</div>
        <h1 class="font-heading text-5xl leading-none">Waitlist Reserve</h1>
        <BaseDivider color="black" class="mt-3 mb-4" />
        <!-- Format HTML here similar to Craft WYSIWYG editor to keep consistency -->
        <div class="home-modal-content">
          <p
            >You are currently on the Alltrue Waitlist Reserve. You are guaranteed to receive the
            next box, and you are first in line to receive it! <br /><br
          /></p>
          <ul
            ><li
              >When our next box is available, you’ll be automatically charged using the payment
              method associated with your account for an Alltrue Seasonal Membership at the full
              regular price of {{ $formatPrice(PLAN_PRICE.quarterly) }}, plus any applicable
              shipping charges and taxes.</li
            ><li
              >To remove yourself from the waitlist unsubscribe online from My Account page &gt;
              Memberships tab by clicking the ‘more menu’ icon or email us at
              <a href="mailto:help@alltrue.com">help@alltrue.com</a>.</li
            ><li
              >Your Membership will renew automatically on a quarterly basis beginning with the next
              season using the payment method associated with your account. Memberships will
              auto-renew at the full regular price, plus any applicable shipping charges and taxes.
              To prevent renewal, you can cancel your Membership online or by emailing us at
              <a href="mailto:help@alltrue.com">help@alltrue.com</a>. All prices listed are in
              USD.</li
            ></ul
          >
        </div>
      </div>
    </BaseModal>
  </Portal>
</template>

<style lang="postcss" scoped>
  .home-modal-content {
    >>> & {
      p {
        @apply leading-close text-md;
      }

      ul {
        list-style-position: inside;

        li::before {
          @apply pr-3;

          content: '\2192';
        }

        li {
          @apply mb-22px leading-close text-md;
        }
      }

      a {
        @apply underline;
      }
    }
  }
</style>

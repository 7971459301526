var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 px-4 md:px-10 shadow-gray-border bg-white py-4 md:py-6"},[_c('p',{staticClass:"mb-4 text-xl flex justify-between items-center"},[_vm._v("My Past Boxes")]),_vm._v(" "),(!_vm.shipments)?[_vm._v("You don't have any shipments to display yet")]:_vm._l((_vm.shipments),function(shipment,index){return _c('div',{key:shipment.orderId,staticClass:"w-full"},[(index > 0)?_c('BaseDivider',{staticClass:"my-4 md:my-8 w-full",attrs:{"weight":2,"color":"dawn-dk2"}}):_vm._e(),_vm._v(" "),_c('BaseAccordion',{class:("w-full " + (_vm.hideMinusIconClass(shipment))),attrs:{"icon":"minus","expanded":shipment.boxId === _vm.latestShipmentBoxId},scopedSlots:_vm._u([{key:"buttonLabel",fn:function(){return [_c('p',{staticClass:"normal-case text-base font-medium leading-close"},[_vm._v("\n            "+_vm._s(shipment.shipmentName)+" | Status:\n            "),_c('span',{staticClass:"font-normal"},[_vm._v(_vm._s(shipment.status))])]),_vm._v(" "),_c('p',{staticClass:"normal-case text-base font-normal leading-close"},[_vm._v("\n            "+_vm._s(_vm.fullName(shipment))+"\n            "),(shipment.trackingUrl)?[_c('span',{staticClass:"mx-1"},[_vm._v("|")]),_vm._v(" "),_c('BaseLink',{staticClass:"underline inline-block",attrs:{"href":shipment.trackingUrl,"target":"_blank"}},[_vm._v("\n                Track\n              ")])]:_vm._e(),_vm._v(" "),(!_vm.isQuarterlyAndAwaitingShipment(shipment))?[_c('span',{staticClass:"mx-1"},[_vm._v("|")]),_vm._v(" "),_c('BaseLink',{staticClass:"underline inline-block",attrs:{"to":{
                  name: 'CustomizeOrderSummary',
                  params: {
                    orderId: shipment.orderId,
                    subscriptionId: shipment.subscriptionId,
                  },
                }}},[_vm._v("\n                Box Summary\n              ")])]:_vm._e()],2)]},proxy:true}],null,true)},[_vm._v(" "),(!_vm.isQuarterlyAndAwaitingShipment(shipment))?_c('ul',{staticClass:"mt-6 grid md:justify-center md:gap-x-2 gap-y-2px md:gap-y-6 grid-cols-1 md:grid-cols-4"},_vm._l((shipment.lineItems),function(ref,choiceIndex){
                var variant = ref.variant;
return _c('li',{key:variant.id},[(_vm.isVariantDataComplete(variant))?_c('CustomizeCard',{attrs:{"variant":variant,"choice-step":choiceIndex + 1,"has-bottom-border":!_vm.screen.md,"layout":_vm.screen.md ? 'vertical' : 'horizontal',"cta":"View"},on:{"details":function($event){return _vm.navigateToDetails(variant.id)}}}):_vm._e()],1)}),0):_vm._e()])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
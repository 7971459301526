<script>
  import { get } from 'vuex-pathify'
  import AccountProfileBox from '@/components/account/AccountProfileBox'
  import AlltruePlusInfoModal from '@/components/account/AlltruePlusInfoModal'
  import WaitlistModal from '@/components/modals/WaitlistModal'
  import screen from '@/helpers/screen'

  export default {
    components: {
      AccountProfileBox,
      AlltruePlusInfoModal,
      WaitlistModal,
    },
    data() {
      return {
        unsubscribeMenuClicked: null,
        highlightMagazineLink: false,
        highlightReactivateLink: false,
        waitlistModalVisible: false,
        alltrueModalVisible: false,
      }
    },
    computed: {
      ...get(['route']),
      ...get('account', [
        'subscriptions',
        'activeSubscriptions',
        'activeSubsWithoutMagazine',
        'billingInfo',
        'loadingProfile',
        'canceledSubscriptions',
        'isSubscriptionWaitlist',
        'isAlltruePlusSubscriber',
      ]),
      ...get('config', ['isCurrentBoxWaitlist']),
      ...get('feature', ['flags']),
      screen,
      isMagazineEnabled() {
        return this.flags['addon-magazine'] ?? false
      },
      isEcoUpgradeEnabled() {
        return this.flags['addon-eco-upgrade'] ?? false
      },
      isUpgradeToAlltruePlusEnabled() {
        return this.flags['upgrade-to-alltrue-plus'] ?? false
      },
      nonExpiredSubscriptions() {
        return this.subscriptions
          ? this.subscriptions.filter((subscription) => !subscription.isExpired)
          : []
      },
    },
    created() {
      window.addEventListener('keydown', this.handleKeyDown)
      window.addEventListener('click', this.close)
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      window.removeEventListener('click', this.close)
    },
    async mounted() {
      if (!this.subscriptions) {
        await this.$store.dispatch('account/fetchProfile')
      }
      if (this.$route.params.section === 'magazine') {
        if (this.activeSubsWithoutMagazine.length === 1) {
          this.$navigate({
            name: 'AccountAddOn',
            params: {
              type: 'magazine',
              subscriptionId: this.activeSubscriptions[0].id,
            },
          })
        } else if (this.activeSubsWithoutMagazine.length > 1) {
          this.highlightMagazineLink = true
          setTimeout(() => {
            this.highlightMagazineLink = false
          }, 5000)
        }
      } else if (this.$route.params.section === 'reactivate') {
        if (this.canceledSubscriptions.length === 1) {
          this.$navigate({
            name: 'AccountSubscriptionEdit',
            params: {
              clickedAction: 'reactivate',
              selectedSubscriptionId: this.canceledSubscriptions[0].id,
              subscriptionType: this.canceledSubscriptions[0].type,
            },
          })
        } else if (this.canceledSubscriptions.length > 1) {
          this.highlightReactivateLink = true
          setTimeout(() => {
            this.highlightReactivateLink = false
          }, 5000)
        }
      }
    },
    methods: {
      handleKeyDown($event) {
        // If the escape key was pressed and the menu is open, dismiss it
        if ($event.keyCode === 27 && this.unsubscribeMenuClicked) {
          this.unsubscribeMenuClicked = null
        }
      },
      close($event) {
        if (!this.$refs[this.unsubscribeMenuClicked]?.[0].contains($event.target)) {
          this.unsubscribeMenuClicked = null
        }
      },
      clickAction(action, id, type) {
        this.$navigate({
          name: 'AccountSubscriptionEdit',
          params: {
            clickedAction: action,
            selectedSubscriptionId: id,
            subscriptionType: type,
          },
        })
      },
      unsubscribeMenuClick(index) {
        this.unsubscribeMenuClicked = this.unsubscribeMenuClicked === null ? index : null
      },
      badgeStatus(subscription) {
        const isSubscriptionWaitlist = this.isSubscriptionWaitlist(subscription)
        const { isCanceled, isExpired, status } = subscription

        if (!isCanceled && !isExpired && isSubscriptionWaitlist) {
          return 'Waitlist'
        }

        if (isCanceled) {
          return 'Canceled'
        } else {
          return status
        }
      },
      subscriptionType(type, addOns) {
        if (type === 'annually') {
          if (addOns?.includes('alltrue-plus')) {
            return 'Alltrue+'
          }
          return 'Annual'
        }
        return 'Seasonal'
      },
      goToAlltruePlusUpgrade(id) {
        this.$navigate({
          name: 'AccountAlltruePlusUpgrade',
          params: {
            subscriptionId: id,
          },
        })
      },
      showAlltruePlusUpgradeLink(type) {
        return (
          this.isUpgradeToAlltruePlusEnabled && type === 'annually' && !this.isAlltruePlusSubscriber
        )
      },
    },
  }
</script>

<template>
  <AccountProfileBox>
    <template v-slot:heading>My Memberships</template>
    <template v-if="!screen.md" v-slot:action>
      <BaseLinkStyled href="https://alltrue.com/gift" color="black"> Gift a Box </BaseLinkStyled>
    </template>
    <ul class="flex flex-wrap">
      <li
        v-for="({ addOns, type, isCanceled, id, address }, index) in nonExpiredSubscriptions"
        :key="index"
        class="flex flex-col md:mr-10 pb-4 md:pb-8 min-w-full md:min-w-80"
      >
        <div>
          <span
            :class="`text-3xs font-semibold uppercase inline-block leading-none py-1 px-1 mb-3px ${
              isSubscriptionWaitlist(nonExpiredSubscriptions[index])
                ? 'bg-winter text-black'
                : 'bg-green text-white'
            }`"
          >
            {{ badgeStatus(nonExpiredSubscriptions[index]) }}
          </span>
          <div class="flex justify-between">
            <div class="capitalize font-medium">
              {{ subscriptionType(type, addOns) }} Membership
              <BaseIcon
                v-if="isSubscriptionWaitlist(nonExpiredSubscriptions[index])"
                type="button"
                label="learn more"
                class="inline-block w-13px top-1"
                @click="waitlistModalVisible = true"
              >
                <IconInfoAlertOutline />
              </BaseIcon>
              <BaseIcon
                v-else-if="subscriptionType(type, addOns) === 'Alltrue+'"
                type="button"
                label="learn more"
                class="inline-block w-13px top-1"
                @click="alltrueModalVisible = true"
              >
                <IconInfoAlertOutline />
              </BaseIcon>
            </div>
            <div :ref="index">
              <BaseMenu
                v-if="!isCanceled"
                data-cy="unsubscribe-menu"
                label="unsubscribe menu"
                :open="unsubscribeMenuClicked === index"
                @click="unsubscribeMenuClick(index)"
                @dismiss="unsubscribeMenuClicked = null"
              >
                <template v-slot:icon>
                  <IconOpenMenu />
                </template>
                <template v-slot:desktop>
                  <BaseLink
                    class="bg-gray-active py-2 px-4 cursor-pointer"
                    data-cy="unsubscribe-text"
                    @click="clickAction('unsubscribe', id)"
                  >
                    Unsubscribe
                  </BaseLink>
                </template>
                <template v-slot:mobile>
                  <BaseLink data-cy="unsubscribe-text" @click="clickAction('unsubscribe', id)">
                    <div class="pl-4 py-7">
                      <span v-if="isSubscriptionWaitlist(nonExpiredSubscriptions[index])">
                        Unsubscribe from waitlist
                      </span>
                      <span v-else> Deactivate your Membership </span>
                    </div>
                  </BaseLink>
                </template>
              </BaseMenu>
            </div>
          </div>
          <div
            v-if="isSubscriptionWaitlist(nonExpiredSubscriptions[index]) && isCanceled"
            class="text-xs"
          >
            <BaseLinkStyled
              :class="
                highlightReactivateLink
                  ? 'bg-sunshine transition duration-500'
                  : 'bg-transparent transition duration-500'
              "
              @click="clickAction('reactivate', id, type)"
            >
              Reactivate
            </BaseLinkStyled>
          </div>
          <div v-else-if="!isSubscriptionWaitlist(nonExpiredSubscriptions[index])" class="text-xs">
            <span v-if="addOns.includes('eco-upgrade')">Eco-Upgrade</span>
            <BaseLinkStyled
              v-else-if="isEcoUpgradeEnabled"
              @click="
                $navigate({
                  name: 'AccountAddOn',
                  params: { subscriptionId: id, type: 'eco-upgrade' },
                })
              "
            >
              Add Eco-Upgrade
            </BaseLinkStyled>
            <span v-if="isMagazineEnabled && isEcoUpgradeEnabled" class="mx-1 text-4xs"
              >&#x25CF;</span
            >
            <span v-if="addOns.includes('magazine')">Premium Magazine</span>
            <BaseLinkStyled
              v-else-if="isMagazineEnabled"
              :class="
                highlightMagazineLink
                  ? 'bg-sunshine transition duration-500'
                  : 'bg-transparent transition duration-500'
              "
              @click="
                $navigate({
                  name: 'AccountAddOn',
                  params: { subscriptionId: id, type: 'magazine' },
                })
              "
            >
              Add Premium Magazine
            </BaseLinkStyled>
            <template v-if="isCanceled">
              <br />
              <BaseLinkStyled
                :class="
                  highlightReactivateLink
                    ? 'bg-sunshine transition duration-500'
                    : 'bg-transparent transition duration-500'
                "
                @click="clickAction('reactivate', id, type)"
              >
                Reactivate
              </BaseLinkStyled>
            </template>
            <template v-if="!isCanceled">
              <template v-if="type === 'quarterly'">
                <br />
                <BaseLinkStyled @click="clickAction('upgrade', id)">
                  Become an Annual Member, Save $20
                </BaseLinkStyled>
              </template>
              <template v-else-if="showAlltruePlusUpgradeLink(type)">
                <br />
                <BaseLinkStyled @click="goToAlltruePlusUpgrade(id)">
                  Become an Alltrue+ Member
                </BaseLinkStyled>
              </template>
            </template>
          </div>
        </div>

        <template v-if="address">
          <BaseDivider class="my-2" />
          <div class="flex justify-between items-center">
            <span class="text-base font-medium">
              Ships to: {{ address.firstName }} {{ address.lastName }}</span
            >
            <BaseIconClickable
              :size="3"
              color="black"
              :to="{
                name: 'AccountProfileChangeSubscriptionAddress',
                params: { subscriptionId: id },
              }"
              label="Change Membership address"
            >
              <IconEdit />
            </BaseIconClickable>
          </div>
          <div class="text-xs">
            <BaseAddress :address="address" />
          </div>
        </template>

        <BaseDivider class="my-2" />
        <div class="flex justify-between items-center">
          <span class="text-base font-medium">Payment</span>
          <BaseIconClickable
            :size="3"
            color="black"
            :to="{ name: 'AccountProfileSettingsPaymentMethod' }"
            label="Change payment method"
          >
            <IconEdit />
          </BaseIconClickable>
        </div>
        <div class="text-xs">
          <BaseBillingInfo v-if="billingInfo" :billing-info="billingInfo" />
          <div v-else>No payment method</div>
        </div>
        <BaseDivider v-if="!screen.md" class="my-2" />
      </li>
    </ul>
    <BaseDivider v-if="screen.md" class="mb-4" />
    <BaseLinkStyled
      v-if="!isCurrentBoxWaitlist"
      color="black"
      class="mr-8"
      :to="{ name: 'SubscribePlan' }"
    >
      Add New Membership
    </BaseLinkStyled>
    <BaseLinkStyled v-if="screen.md" href="https://alltrue.com/gift" color="black">
      Gift a Box
    </BaseLinkStyled>
    <WaitlistModal :open="waitlistModalVisible" @dismiss="waitlistModalVisible = false" />
    <Portal to="modal">
      <AlltruePlusInfoModal :open="alltrueModalVisible" @dismiss="alltrueModalVisible = false" />
    </Portal>
  </AccountProfileBox>
</template>

<script>
  import screen from '@/helpers/screen'

  export default {
    props: {
      open: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        featureList: [
          'Bonus product (worth $30+) in every box',
          'Exclusive Alltrue+ members only T&#8209;shirt <br> <div class="text-3xs">(one-time Alltrue+ welcome gift sent in your next box)</div>',
          '$40 in market credits /year <br> <div class="text-3xs">($10/season automatically applied to your order)</div>',
        ],
      }
    },
    computed: {
      screen,
    },
  }
</script>

<template>
  <BaseFeaturesModal :open="open" :feature-list="featureList" @dismiss="$emit('dismiss')">
    <template v-slot:kicker> Perks of </template>
    <template v-slot:header> Alltrue+ </template>
  </BaseFeaturesModal>
</template>

<script>
  import { get } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import CustomizeCard from '@/components/customize/CustomizeCard'

  export default {
    components: {
      CustomizeCard,
    },
    props: {
      /** Whether the view is to view all orders (viewing all orders will display as modal on desktop and take up full screen on mobile) */
      viewAll: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...get('account', ['shipments', 'latestShipmentBoxId']),
      screen,
    },
    methods: {
      fullName(displayedOrder) {
        const { address } = displayedOrder
        if (address) {
          const { firstName, lastName } = address
          return `${firstName} ${lastName}`
        }
        return ''
      },
      navigateToDetails(variantId) {
        this.$navigate({
          name: 'AccountMyPastBoxesDetails',
          params: {
            variantId,
          },
        })
      },
      isVariantDataComplete(variant) {
        return variant.previewCardImages?.[0] && variant.brand && variant.productTitle
      },
      isQuarterlyAndAwaitingShipment(shipment) {
        return (
          (shipment?.shipmentName?.toLowerCase()?.includes('seasonal') ||
            shipment?.shipmentName?.toLowerCase()?.includes('quarterly')) &&
          shipment?.status?.toLowerCase() === 'awaiting shipment'
        )
      },
      hideMinusIconClass(shipment) {
        return this.isQuarterlyAndAwaitingShipment(shipment) ? 'hide-minus-icon' : ''
      },
    },
  }
</script>

<template>
  <div class="mt-2 px-4 md:px-10 shadow-gray-border bg-white py-4 md:py-6">
    <p class="mb-4 text-xl flex justify-between items-center">My Past Boxes</p>
    <template v-if="!shipments">You don't have any shipments to display yet</template>
    <template v-else>
      <div v-for="(shipment, index) in shipments" :key="shipment.orderId" class="w-full">
        <BaseDivider v-if="index > 0" class="my-4 md:my-8 w-full" :weight="2" color="dawn-dk2" />
        <BaseAccordion
          icon="minus"
          :class="`w-full ${hideMinusIconClass(shipment)}`"
          :expanded="shipment.boxId === latestShipmentBoxId"
        >
          <template v-slot:buttonLabel>
            <p class="normal-case text-base font-medium leading-close">
              {{ shipment.shipmentName }} | Status:
              <span class="font-normal">{{ shipment.status }}</span>
            </p>
            <p class="normal-case text-base font-normal leading-close">
              {{ fullName(shipment) }}
              <template v-if="shipment.trackingUrl">
                <span class="mx-1">|</span>
                <BaseLink
                  class="underline inline-block"
                  :href="shipment.trackingUrl"
                  target="_blank"
                >
                  Track
                </BaseLink>
              </template>
              <template v-if="!isQuarterlyAndAwaitingShipment(shipment)">
                <span class="mx-1">|</span>
                <BaseLink
                  class="underline inline-block"
                  :to="{
                    name: 'CustomizeOrderSummary',
                    params: {
                      orderId: shipment.orderId,
                      subscriptionId: shipment.subscriptionId,
                    },
                  }"
                >
                  Box Summary
                </BaseLink>
              </template>
            </p>
          </template>
          <ul
            v-if="!isQuarterlyAndAwaitingShipment(shipment)"
            class="mt-6 grid md:justify-center md:gap-x-2 gap-y-2px md:gap-y-6 grid-cols-1 md:grid-cols-4"
          >
            <li v-for="({ variant }, choiceIndex) in shipment.lineItems" :key="variant.id">
              <CustomizeCard
                v-if="isVariantDataComplete(variant)"
                :variant="variant"
                :choice-step="choiceIndex + 1"
                :has-bottom-border="!screen.md"
                :layout="screen.md ? 'vertical' : 'horizontal'"
                cta="View"
                @details="navigateToDetails(variant.id)"
              />
            </li>
          </ul>
        </BaseAccordion>
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
  .hide-minus-icon >>> {
    svg {
      @apply hidden;
    }
  }
</style>

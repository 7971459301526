<script>
  import { get } from 'vuex-pathify'
  import AccountProfileSubscriptionsPlans from '@/components/account/AccountProfileSubscriptionsPlans'
  import AccountProfileBoxShipments from '@/components/account/AccountProfileBoxShipments'
  import screen from '@/helpers/screen'
  import { events } from '@/helpers/gtm'

  export default {
    components: {
      AccountProfileSubscriptionsPlans,
      AccountProfileBoxShipments,
    },
    computed: {
      ...get(['route']),
      ...get('general', ['isUnsubscribeAction']),
      ...get('customize', ['selectedSubscription']),
      screen,
      modalHeight() {
        if (this.$route.name === 'AccountAddOn') {
          return 'md'
        } else {
          return undefined
        }
      },
    },
    methods: {
      handleDismiss() {
        if (this.isUnsubscribeAction) {
          this.sendUnsubscribeExitedEvent()
        }
        this.$navigate({
          name: 'AccountProfileSubscriptions',
        })
      },
      sendUnsubscribeExitedEvent() {
        events.log({
          name: 'exited cancellation',
          data: {
            plan: this.selectedSubscription?.type,
          },
        })
      },
    },
  }
</script>

<template>
  <div>
    <div role="tabpanel" aria-label="Memberships" class="w-full flex flex-col">
      <AccountProfileSubscriptionsPlans />
      <AccountProfileBoxShipments />
      <div v-if="$route.name === 'AccountMyPastBoxesDetails'">
        <RouterView />
      </div>
    </div>
    <Portal to="modal">
      <BaseModal
        size="sm"
        :height="modalHeight"
        :limit-screen-height="isUnsubscribeAction"
        :no-vertical-padding="$route.name === 'AccountAddOn'"
        :open="
          $route.name !== 'AccountProfileSubscriptions' &&
          $route.name !== 'AccountMyPastBoxesDetails'
        "
        bg-color="dawn"
        dismissible
        @dismiss="handleDismiss"
      >
        <RouterView />
      </BaseModal>
    </Portal>
  </div>
</template>
